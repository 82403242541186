





.container-fluid {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-box {
  position: relative;
  width: 100%;
  /* height: 100%; */
  overflow: hidden; /* Ensure the video does not overflow */
}

.bannerVideo {
  width: 100%;
  height: 800px;
  object-fit: cover; 
}

.carousel-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

@media (max-width: 768px) {
  .carousel-container {
    bottom: 10px; 
  }
  .bannerVideo {
    width: 100%;
    height:400px;
    object-fit: cover;}
}



@media (max-width: 576px) {
  .carousel-container {
    bottom: 5px; 
  }
  .bannerVideo {
    width: 100%;
    /* height: 400px; */
    object-fit: cover;
    border-bottom-left-radius: 100px; /* Adjust the radius as needed */
    border-bottom-right-radius: 100px; /* Adjust the radius as needed */
  }
}

/* Additional styles for the Carousel component if needed */
