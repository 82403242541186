body {
  font-family: Roboto, sans-serif;
}

nav.nav-bar--hidden {
  transform: translateY(-100%);
}

.heading {
  margin-bottom: -10px;
  /* font-weight: ; */
  letter-spacing: 2px;
  font-weight: 700;
}

.section {
  font-size: 2rem;
  font-weight: 400;
  max-width: 780px;
  /* margin: 10px 0 0 10px; */
  /* text-align: center; */
}

.textColorOpacity {
  color: #b9c1cc !important;
}

.section-2 {
  line-height: 2;
  max-width: 721px;
  font-style: normal;
  margin: 20px auto 60px;
  text-align: center;
}

/* TextAnimation.css */

.moving-text-container {
  overflow: hidden;
  background-color: #f8f9fa;
  padding: 10px;
}

.moving-text {
  white-space: nowrap;
  font-size: 100px;
  color: transparent;
  -webkit-text-stroke: 1px rgb(247, 138, 131);
  animation: moveRightToLeft 10s linear infinite;
}

@keyframes moveRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


/* //////////////////   Footer   ///////////////// */

.footer {
  color: #fff;
  padding-top: 100px;
  /* padding-bottom: 20px; */
}
.Footer-Container {
  flex-direction: row;
  /* margin-top: -100px; */
  display: flex;
  justify-content: space-evenly;

}
.footer-logo h2{
  color: #04a5bf;
  font-size: 50px;
}
.footer-logo p{
  font-weight: 450;
  color: #777777;
}

li {
  list-style: none;
  
}
.useful-link h2 {
  /* padding-bottom: 10px; */
  font-size: 20px;
  font-weight: 600;
  color:  #04a5bf;
}
.useful-link{
  margin-bottom: 15px;
}

.use-links {
  line-height: 32px;
}
.use-links li i {
  font-size: 14px;
  padding-right: 8px;
  color: #898989;
  text-decoration: none;
  
}
.use-links li a {
  font-size: 15px;
  font-weight: 450;
  color: #777777;
  text-decoration: none;
}
.use-links li a:hover {
  color: white;
}

/* social link footer */

.social-links h2 {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  color:   #04a5bf;
}
.social-links{
  margin-bottom: 15px;
}

.social-icons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #777777;
}
.use-links{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #777777;
}
.social-icons a {
  color: #777777;
}
.social-icons a:hover {
  color: white;
}
.social-icons li a {
  font-weight: 450;
  font-size: 16px;
  margin-right: 12px;
  text-decoration: none;
}

/* footer address link */

.address h2{
	padding-bottom: 10px;
	font-size: 20px;
  font-weight: 600;
  color:   #04a5bf;
}
.address img{
	align-items: center;
}
.address-links li a{
	color: #303030;
  font-size: 15px;
  font-weight: 450;
	color: #777777;
  text-decoration: none;

}
.address-links li i{
	font-size: 16px;
  padding-right: 8px;
	color: #82074a;

}
.address-links li i:nth-child(1){
	padding-top: 9px;
}
.address-links .address1{
	font-weight: 450;
  font-size: 15px;
	display: flex;
}
.address-links{
	  line-height: 25px;
		color: #777777;
}


/* ------------------------new --------------------------------- */


@media (max-width:510px) {
  .footer {
   /* height: 613px; */
   color: #fff;
   /* padding-top: 0px; */
  }
  
.address h2{
  padding: 0px;
	font-size: 20px;
  font-weight: 600;
  color:   #04a5bf;
}
}


@media screen and (max-width: 510px) {

  .address-links{
	  margin-bottom: 20px;
}

  .Footer-Container {
    flex-direction: column;
    margin-left: 50px;
  }
  .social-icons{
    flex-direction: row;
  }
  .use-links{
    flex-direction: row;
  }
  
  .use-links {
    line-height: 27px;
    
  }
  .footer-logo h2{
    margin-left: 20%;
    font-size: 30px;
  }
  .KEEP-IN-TOUCH{
    display: none !important;
  }
  .text_rounded{
    text-align: center;
    padding: 35px;
  }
  .image_width{
    width: 100%;
  }
  .text_ped {
  margin: 30px;
  }
  .welcome-main {
   
    
    margin-right: 0px !important;
   
  }
  

  
  @media (max-width: 768px) {
    .controls {
      display: none !important;
    }

   
  }
  
}

.copy-right-sec{
	padding: 0.8rem;
  background-color: #04a5bf;
 color: black;
  text-align: center;
  font-weight: 450;
}


/* footer section end */

.wrapper {
  height: 10%;
  width: 100%;
}

.wrapper {
  height: 10%;
  width: 100%;
}

.imgHover {
  transition: 1s;
}

.imgHover:hover {
  filter: blur(2px);
  cursor: pointer;
  transform: scale(1.5);
}

.logo {
  background-color: transparent;
}
.containerDiv {
  max-width: 1600px !important;
}

/* Responsive styles for screens up to 991 pixels */
@media (max-width: 990px) {
  .containerDiv {
  margin-top: 0px;
  }
}


.pr-30 {
  padding-right: 30px !important;
}

.fs-25 {
  font-size: 25px;
}

.fw-700 {
  font-weight: 700;
}

.hoverColor:active {
  color: red;
}

.imageBanner {
  animation:
    nudge 5s infinite;
  cursor: pointer;
  /* opacity: 0.8; */
}

.imageBanner:hover {
  animation: roll 2s;
  /* transform: rotate(30deg); */
}



@keyframes roll {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 530px) {
  .imageBanner {
    width: 25rem;
  }
}

@keyframes nudge {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 38px);
  }
}

.animatio_banner {
  animation: nudges 10s infinite;
  background-repeat: no-repeat;
}

@keyframes nudges {
  0%,
  100% {
    background-image: url("./Assets/Image/bannerImg.jpg");
  }

  25% {
    background-image: url("./Assets/Image/1.jpg");
  }

  50% {
    background-image: url("./Assets/Image/3.jpg");
  }
}

.lead {
  color: #999;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  margin: 0;
  text-align: center;
}

.contect_banner {
  background-image: url("./Assets/Image/contect_banner.jpg");
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Source Sans Pro" !important;
}

.contect_maps {
  /* height: 100vh; */
  background-image: url("./Assets/Image/map-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Source Sans Pro" !important;
  background-color: rgba(255, 255, 255, 0.7);
}

.border_banner {
  height: 30px;
  background-color: rgb(221, 153, 51);
  width: 5px;
  position: absolute;
  bottom: 21px;
}

.banner {
  margin-left: 117px;
  margin-top: 200px;
}

.banner h2 {
  color: #f1f1f1;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-left: 20px;
  position: relative;
}

.welcome-main {
  text-align: center;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 12%);
  border: 4px solid #f9fbff;
  /* height: 396px; */
  padding: 45px 15px;
  border-radius: 15px 70px 15px 70px;
  margin-right: 25px;
  margin-bottom: 25px;
  min-height: 444px;
}

.box_image {
  /* -webkit-box-shadow: 0 0.5rem 1rem rgb(237 154 52 / 46%); */
  box-shadow: 0 0.5rem 1rem rgb(237 154 52 / 46%);
  background: linear-gradient(50deg, #00b8d9 0, #0077d9 100%);
  height: 80px;
  width: 80px;
  border-radius: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.box_image2 {
  box-shadow: 0 0.5rem 1rem rgb(255 171 0 / 40%);
  background: linear-gradient(
    to right top,
    #ffbc00,
    #ff9907,
    #ff741f,
    #f94c31,
    #eb1241
  );
  height: 80px;
  width: 80px;
  border-radius: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.box_image3 {
  box-shadow: 0 0.5rem 1rem rgb(110 0 255 / 40%);
  background: linear-gradient(50deg, #0af9e3 0, #04a5bf 100%);
  height: 80px;
  width: 80px;
  border-radius: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.box_image4 {
  background-image: linear-gradient(50deg, #528c05 0, #b6d605 100%);
  box-shadow: 0 0.5rem 1rem rgb(237 154 52 / 46%);
  height: 80px;
  width: 80px;
  border-radius: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.welcome-main:hover {
  z-index: -1;
  background: linear-gradient(50deg, #00b8d9 0, #0077d9 100%);
  transition: 2s;
  border-radius: 15px 70px 15px 70px;
  animation: icons 1s alternate;
}



.wow2:hover {
  background: linear-gradient(
    to right top,
    #ffbc00,
    #ff9907,
    #ff741f,
    #f94c31,
    #eb1241
  );
  background-position: left;

  
}

.wow3:hover {
  background: linear-gradient(50deg, #0af9e3 0, #04a5bf 100%);
}

.wow4:hover {
  background-image: linear-gradient(50deg, #528c05 0, #b6d605 100%);
}
/* .texthover:hover{
  color: #ffffff;
} */
/* BusinessStrategy.css */

.white-text {
  color: white;
  cursor: pointer;
}


@keyframes icons {
  100% {
    transform: translate(0px, 5px);
  }

  /* 50% {
    transform: translate(0, 0px);
  } */
}

.box_row {
  max-width: 78rem;
  margin-right: 50px;
  margin-left: 50px;
  flex-direction: row;
  display: flex;
  flex-flow: wrap;
}

.welcome-main>p {
  line-height: 28px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  /* color: #808899; */
}

.welcome-main:hover {
  color: white;
}
.welcome-main>p:hover {
  line-height: 28px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fff;
}

#btn-back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  right: 20px;
  /* display: none; */
  border-radius: 20px;
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: linear-gradient(50deg, #00b8d9 0, #0077d9 100%);
}

.carrers_dashboard {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
 margin: 40px;
  flex-flow: wrap;
}

.separator_on {
  max-width: 70rem;
  /* margin-left: 30px; */
   width: '50%';
   background-color: 'rgba(255, 255, 255, 0.7)';
   padding: '20px';
   border-radius: '8px';
   margin: '0 auto';
   text-align:center;
   margin-top: 40px;
   
}
.backgroundcolor {

  position: 'absolute';
  top: 0;
  left: 0;
  width: '100%';
  height: '100%';
  background-color: 'rgba(0, 0, 0, 0.5)'; 
  backdrop-filter: 'blur(10px)';
  z-index: -1;
  
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.form-control {
  border: 1px solid #e1e1e1;
  box-shadow: 0 1px #fff, inset 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 3px;
  font-size: 12px;
  height: 40px;
  line-height: 22px;
  margin-bottom: 22px;
  min-height: 40px;
  padding: 8px 12px;
  width: 100%;
  /* background: none; */
  height: 4rem;
}

.form-control_comment {
  border: 1px solid #e1e1e1;
  box-shadow: 0 1px #fff, inset 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 3px;
  font-size: 18px;
  height: 40px;
  line-height: 22px;
  margin-bottom: 22px;
  min-height: 40px;
  padding: 8px 12px;
  width: 100%;
  /* background: none; */
  height: 10rem;
  font-weight: 300;
}

.contact_form {
  align-self: center;
  flex-direction: column;
  min-width: 45%;
  /* margin-top: 100px; */
  /* margin-bottom: 50px; */
  /* display: flex; */
  border: 1px solid #ededed;
  box-shadow: 0 5px 14px rgb(0 0 0 / 10%);
  background-color: #f2f3f5bd;
  padding: 40px 30px;
}
.buttons-box {
  background-color: #428bca;
  width: fit-content;
  border-radius: 5px;
  color: #ffff;
}

.btn-light {
  padding: 13px 40px !important;
  font-size: 18px !important;
  border: 2px solid #fd6802 !important;
  color: #ffffff !important;
  background: linear-gradient(50deg, #00b8d9 0, #0077d9 100%);
  border-radius: 1px 20px !important;
  transform: rotateY(40deg);
  animation: two 2s infinite;
  animation-direction: alternate;
}

.sumbit_button {
  padding: 8px 20px !important;
  margin-top: 10px;
  font-size: 18px !important;
  border: 2px solid #fd6802 !important;
  color: #ffffff !important;
  background: linear-gradient(50deg, #00b8d9 0, #0077d9 100%);
  border-radius: 1px 20px !important;
  transform: rotateY(40deg);
  animation: two 2s infinite;
  animation-direction: alternate;
}

.sumbit_button:hover {
  border: 2px solid #fd6802 !important;
  color: #ffffff !important;
  background: #302d2c !important;
  border-radius: 1px 20px !important;
}

.btn-light:hover {
  padding: 13px 40px !important;
  font-size: 18px !important;
  border: 2px solid #fd6802 !important;
  color: #ffffff !important;
  background: #302d2c !important;
  border-radius: 1px 20px !important;
}

@keyframes two {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(30deg);
  }
}

.nav_bar_sty {
  color: #000 !important;
  font-size: 20 !important;
  font-weight: 500 !important;
  font-family: "Open Sans" !important;
  text-transform: uppercase !important;
  letter-spacing: 2px !important;
  text-decoration-line: none;
  border: 5px solid;
  border-image: repeating-linear-gradient(50deg, #00b8d9 0, #0077d9 100%) 2;
  -webkit-mask: conic-gradient(
        from 180deg at top 8px right 8px,
        #0000 90deg,
        #000 0
      )
      var(--_i, 200%) 0 /200% var(--_i, 8px) border-box no-repeat,
    conic-gradient(at bottom 8px left 8px, #0000 90deg, #000 0) 0
      var(--_i, 200%) / var(--_i, 8px) 200% border-box no-repeat,
    linear-gradient(#000 0 0) padding-box no-repeat;
  transition: 0.3s, -webkit-mask-position 0.3s 0.3s;
}

.nav_bar_sty:hover {
  /* background: -webkit-linear-gradient(50deg, #00B8D9 0, #0077d9 100%); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  /* text-decoration: underline #00B8D9; */
  /* line-height: 15px; */
  --_i: 100%;
  /* color: #CC333F; */
  transition: 0.3s, -webkit-mask-size 0.3s 0.3s;
}

.active {
  background: -webkit-linear-gradient(50deg, #00b8d9 0, #0077d9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline #00b8d9;
  /* line-height: 15px; */
}

.hover_effect {
  transition: rotate 1s ease-in-out;
  cursor: pointer;
  margin-left: 55px;
}

@media screen and (max-width: 510px) {
  .footer_Bg {
    height: 39rem !important;
  }
  .social-links h2 {
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color:   #04a5bf;
  }
  .useful-link h2 {
    /* padding-bottom: 10px; */
    font-size: 14px;
    font-weight: 600;
    color:  #04a5bf;
  }
  .address h2{
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
    color:   #04a5bf;
  }
  .text_hide{
    display: none;
  }
  .hover_effect {
    transition: rotate 1s ease-in-out;
    cursor: pointer;
    margin-left: 0%;
  }
  
}

.admin {
  width: 100% !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.hover_effect:hover {
  rotate: y 90deg;
  /* rotate: z 90deg; */
  /* rotate: x 90deg; */
}

.rtin-title {
  font-size: 2.25rem;
  line-height: 3.125rem0;
  margin-bottom: 0.9375rem;
  font-weight: 700;
  font-family: Roboto, sans-serif;
}

.sub-text {
  width: 32%;
  text-align: "center";
  margin-left: auto;
  margin-right: auto;
  color: #646464;
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
}

.email_input {
  display: inline-block;
  padding: 10px;
  width: 91%;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  /* border-color: #d6d6d6; */
  background-color: transparent !important;
  color: #fff !important;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.message_alert {
  position: absolute;
  bottom: 127px;
}

/* side Bar Css  */

#header {
  position: relative;
  width: 300px;
  margin-right: 10px;
}

#header .pro-sidebar {
  height: 100vh;
}

#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  font-weight: bold;
}

#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}

#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
}

#header .logo {
  padding: 20px;
}

/* @media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
} */

.menu_sideBar {
  background: linear-gradient(0deg, #fece00 0%, #ffef72 100%);
  /* display: flex;
  height: 100vh; */
  width: 80%;
  border-radius: 20px;
}

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.About-heading-img {
  background-repeat: no-repeat;
  width: auto;
  height: 520px;
  background-size: cover;

  background-image: url("../src/Assets/Image/About-img01.avif");
}


.who-we-Are {
  display: flex;
  margin-top: 5%;
  gap: 50px;
  justify-content: center;
  margin-inline: 25px;
}
@media screen and (max-width: 1024px) {

  .who-we-Are {
    display: flex;
    margin-top: 5%;
    gap: 50px;
    justify-content: center;
    margin-inline: 20px;
  max-width: 768px; 

  }
}
@media screen and (max-width: 768px) {
  .Service-card {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
      gap: 14px;
      justify-content: center;
      margin-top: 10px;
      margin-inline: auto; 
      max-width: 768px; 
  }
}
@media screen and (max-width: 510px) {
  .Service-card {
      display: grid !important;
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 14px;
      justify-content: center;
      margin-top: 10px;
      margin-inline: auto; 
    
  }
}
@media screen and (max-width: 768px) {

  .who-we-Are {
    flex-direction: column-reverse;
    gap: 0px;
  }
}
@media screen and (max-width: 510px) {
  .About-heading-img {
    height: 250px !important;
  }
  .who-we-Are {
    flex-direction: column-reverse;
    gap: 0px;
  }
  .Heading-h2 {
    text-align: start;
  }
  .About-p {
    text-align: center;
    padding: 5%;
  }
  .Who-We-img {
    height: 250px !important;
    width: 100%;
  }
  .Services-heading {
    margin-bottom: 15% !important;
  }
  .Card-logo-img {
    width: 30% !important;
  }
}
.about-h1 {
  color: white;
  font-weight: 700;
  text-transform: capitalize;
  font-style: normal;
  font-size: 55px;
}
.about-heading {
  color: #00b8d9;
  padding-top: 200px;
}
.About-text {
  font-size: 16px;
  color: white;
  font-weight: 400px;
}


.Who-We-img {
  height: 350px;
  border-radius: 5%;
  /* margin: 5%; */
}
.Heading-h2 {
  font-size: 40px;
  font-weight: 700px;
  color: #00b8d9;
  /* margin: 5%; */
}
.About-p {
  line-height: 2;
  max-width: 721px;
  font-style: normal;

  text-align: center;
}
.Services-heading {
  text-align: center;
  margin-top: 3%;
  /* margin-bottom: 5%; */
}

/* card-contaner */

.Service-card {
  display: flex;
  justify-content: center;
  position: relative;
  /* flex-wrap: wrap; */
  gap: 14px;
  margin-top: 10px;
  margin-inline: 25px;
}
.Card-logo-img {
  width: 35%;
}

.Services-contaner {
  position: relative;
  display: block;
  /* width: 320px; */
  /* height: 360px; */
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgb(0, 0, 0, 0.5);
  background-color: #f2f8f9;
  transition: 0.3s;
  margin-top: 6%;
  /* margin-bottom: 80px; */
}

.Service-logo-box {
  position: relative;
  text-align: center;
  transform: translateY(-65px);
}
.service-contant {
  transform: translateY(-65px);
  margin-top: 0px;
  text-align: center;
  padding: 15px;
  font-family: sans-serif;
}
.service-contant p {
  color: #808899;
  line-height: 28px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

/* callOut-redContainer */

.callout-section {
  /* margin-top: -100px;
  min-height: 450px; */
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  box-shadow: 0px 5px 20px rgb(0, 0, 0, 0.5);
  flex-direction: column;
  background-image: url("../src/Assets/Image/img-banner-about-img.png");
  background-size: cover;
  height: 550px;
  background-attachment: fixed;
  background-position: center;
}






.Callout-Container {
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; */
}

@media screen and (max-width: 767px) {
  .Callout-section-heading {
    padding-top: 30px;
  }
}

.callOutH2 {
  padding-top: 10px;
  font-size: 22px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
.callOut-P {
  text-align: center;
  color: #fff;
  font-weight: 500 !important;
  margin: auto;
  width: 100%;
  font-size: 17px;
}

.allcircles {
  position: absolute;
  /* margin-top: 100px; */
  min-height: 100px;
  bottom: 65px;
  
}
.widthCard {
  width: 100% !important;
}
.allcircles ul {
  min-height: 100px;
  display: flex;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  justify-content: space-evenly;
}
.allcircles ul li {
  position: relative;
  flex: 1;
  list-style: none;
  margin-left: 64px;
}
@media screen and ((max-width: 1190px)) {
  .circle1 {
    background-color: #fff;
    height: 70px !important;
    width: 70px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-shadow: 0px 0px 20px rgba(56, 55, 59, 0.4);
    animation-name: updown;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    position: absolute;
  }
  .allcircles ul li {
    margin-left: -35px;
  }
}
.li {
  text-align: -webkit-match-parent;
}

.circle1 {
  background-color: #fff;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(56, 55, 59, 0.4);
  animation-name: updown;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: absolute;
}
@keyframes updown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
}
@media screen and (max-width: 590) {
  .circleapp {
    height: 50px !important;
    width: 50px !important;
  }
  .circle1small {
    height: 65px !important;
    width: 65px !important;
  }
  .circle1small2 {
    height: 70px !important;
    width: 70px !important;
  }
  
}
.circleapp {
  height: 120px;
  width: 120px;
}
.appcircle {
  top: -26%;
}
.circleapple {
  top: 50%;
  animation-delay: 0.5s;
}

.circle1 img {
  height: 40px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

.circle1small {
  height: 75px !important;
  width: 75px !important;
}
.circlenotepad {
  top: -53%;
  animation-delay: 0.8s;
}

.circlebook {
  top: 50%;
  animation-delay: 1s;
}
.circlechat {
  top: -89%;
  animation-delay: 1.5s;
}
.circle1small2 {
  height: 80px !important;
  width: 80px !important;
}

.circlecoffe {
  top: 30%;
}

@media screen and (max-width: 510px) {
  .circle1 img {
    height: 20px;
  }

  .circleapp img {
    height: 40px !important;
  }
  .empty-section{
    display: none;
  }
  
}

/* .empty-section {
  margin-top: 60px;
  height: 50px;
} */
