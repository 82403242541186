.service {
    position: relative;
    width: 100%;
    /* padding: 45px 0 15px 0; */
    /* align-items: center; */
    /* text-align: center; */

}
.section-header{
    text-align: center;
    margin-bottom: 20px;
    padding: 60px 0;
}

.hr-on-hover:hover {
    background-color: #008374; 
  }


.service-item {
    position: relative;
    overflow: hidden;
    box-shadow: 0px 5px 20px rgb(0, 0, 0, 0.5);
  }
  
  .service-item::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    background-color:#D2E4E8;
    border-radius: 50%;
    opacity: 0;
   
    transform: scale(5);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    left: 15%; 
    top: 2%; 
    z-index: -1;
  }
  
  .service-item:hover::before {
    opacity: 1;
    transform: scale(1);
  }
  .service-icon {
    z-index: 1; 
  }

  
  .read-more-button {
    background-color: #008374;
    color: white; 
    border: none; 
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px; 
    cursor: pointer;
    border-radius: 5px; 
  }
  
  .read-more-button:hover {
    background-color: #45a049;
  }
  
 
.containerimage {
    position: relative;
    width: 100%;
    /* height: auto; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto;
}
.container-custom {
    width: 85%; 
    margin: 0 auto; 
   
  }
  .backgrouncontainer{
    background: #eeeeee;
    width: 100%;
  }
@media (min-width: 768px) {
    /* .service {
        padding: 45px 60px 15px 60px;
    } */
}

.service .service-item {
    position: relative;
    width: 95%;
    padding: 25px 30px;
      background: #fff;
    border-radius: 30px 30px 0 0;
    margin-bottom: 30px;
    z-index: 1;
}

.service .service-item:hover {
    background: #fff;
}

.service .service-item h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.service .service-item img {
    height: 80px;
}

.service .service-item p {
    position: relative;
    margin: 20px 0 0 0;
    font-size: 16px;
}

.service-row {
    position: relative;
    width: 100%;
    /* padding: 45px 0; */
    /* background: linear-gradient(to bottom, rgba(0, 0, 0, .03) 96%, rgba(0, 0, 0, .1), rgba(0, 0, 0, .2)); */
    
}
.custom-width {
    width: 85%; 
    margin: 0 auto; 
  }

  .zoom-on-hover {
    transition: transform 0.3s;
  }
  
  .zoom-on-hover:hover {
    cursor: pointer;
    transform: scale(1.1); 
  }
.service-row .service-row-img {
    position: relative;
    width: 100%;
    padding: 0 30px;
    overflow: hidden;
}

.service-row .service-row-img img {
    max-width: 100%;
}

@media(max-width: 767.98px) {
    .service-row .service-row-img {
        margin-bottom: 30px;
    }
}

.service-row .service-row-text {
    position: relative;
    width: 100%;
    padding: 0 30px;
}

.service-row h2.section-title {
    position: relative;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.service-row h2.section-title::after {
    position: absolute;
    content: "";
    width: 60px;
    height: 3px;
    bottom: 0;
    left: 0;
    background: linear-gradient(to left, #4F84C4, #00539C, #4F84C4);
    border-radius: 0 100% 100% 0; 
}

.service-row p {
    font-size: 16px;
}

.service-row a.btn {
    margin-top: 15px;
    padding: 10px 35px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    background: linear-gradient(#4F84C4, #00539C);
    transition: .5s;
}

.service-row a.btn:hover {
    background: linear-gradient(#00539C, #4F84C4);
}

.service-row .service-row-col .col-sm-6 {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
}

.service-row .service-row-col-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    padding: 8px 0;
    text-align: center;
    color: #ffffff;
    background: #4F84C4;
}

.service-row .service-row-col-text {
    width: calc(100% - 55px);
}

.service-row .service-row-col-text h3 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 3px;
}

.service-row .service-row-col-text p {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
}

@media (max-width: 768px) {
    .service-row .service-row-img,
    .service-row .service-row-text {
        padding: 0;
    }
}


/* --------------------------------------------------------------------------------------------------------- */
.call-to-action{
  background-color: rgb(252, 244, 244);
}

.call-to-action .container5 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../Assets/Image/cta-bg.jpg") center center;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 15px;
  overflow: hidden;
 
  }
  .container5 {
    position: relative;
    width: 100%;
    /* height: auto; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px auto;
  }
  .call-to-action h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .call-to-action p {
    color: #fff;
    margin-bottom: 20px;
  }
  
  .call-to-action .play-btn {
    width: 94px;
    height: 94px;
    margin-bottom: 20px;
    background: radial-gradient(var(--color-primary) 50%, rgba(0, 131, 116, 0.4) 52%);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }
  
  .call-to-action .play-btn:before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(0, 131, 116, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .call-to-action .play-btn:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .call-to-action .play-btn:hover:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  .call-to-action .play-btn:hover:after {
    border-left: 15px solid var(--color-primary);
    transform: scale(20);
  }
  
  .call-to-action .cta-btn {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 48px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .call-to-action .cta-btn:hover {
    background: var(--color-primary);
    border: 2px solid var(--color-primary);
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  