/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin and padding and reset fonts */


/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core body defaults */
body {
  /* min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5; */
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
:root {
  --green-light: hsl(123deg, 47%, 74%);
  --green-dark: #2e7f32;
  --blue-light: hsl(210deg, 47%, 74%);
  --blue-dark: #2e577f;
  --orange-light: hsl(34deg, 93%, 77%);
  --orange-dark: #b66a07;
  --purple-light: hsl(231deg, 44%, 76%);
  --purple-dark: #334084;
  --yellow-light: hsl(54deg, 76%, 79%);
  --yellow-dark: #afa018;
  --brown-light: hsl(20deg, 41%, 69%);
  --brown-dark: #68402c;
  --red-light: hsl(0deg, 59%, 76%);
  --red-dark: #922626;
  --pink-light: hsl(295deg, 42%, 72%);
  --pink-dark: #6e2f74;
  --white-light: hsl(0deg, 0%, 100%);
  --white-dark: #999999;
}

body {
  /* font-family: system-ui;
  display: grid;
  min-height: 100vh;
  place-content: center;
  overflow: hidden;
  background-color: #2e577f; */
}

.orbit {
  --size: 12rem;
  --speed: 120s;
  /* margin-top: 10%;
  margin-bottom: 10%; */
}
.orbit .center-image {
  width: var(--size);
  position: relative;
  z-index: 10;
}
.orbit .center-image img {
  position: absolute;
  transition: opacity 500ms;
}
.orbit .center-image img:not(:first-child):hover {
  opacity: 0;
}
.orbit ul {
  display: grid;
  place-items: center;
  width: var(--size);
  height: var(--size);
  position: relative;
  list-style: none;
  --icon-bg: var(--green-light);
  --text-bg: var(--green-dark);
  transform-origin: center;
  animation: orbit var(--speed) linear infinite;
}
.orbit ul:hover {
  animation-play-state: paused;
  --orbit-play-state: paused;
}
.orbit li {
  --icon-bg: pink;
  --text-bg: blue;
  position: absolute;
  width: 8rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  font-weight: 500;
  color: var(--white-light);
  text-align: center;
  line-height: 1;
  display: grid;
  place-items: center;
}
.orbit li:hover {
  --throb-play-state: paused;
}
.orbit li:nth-child(1) {
  --throb-delay: 0ms;
}
.orbit li:nth-child(2) {
  --throb-delay: 500ms;
}
.orbit li:nth-child(3) {
  --throb-delay: 1000ms;
}
.orbit li:nth-child(4) {
  --throb-delay: 1500ms;
}
.orbit li:nth-child(5) {
  --throb-delay: 2000ms;
}
.orbit li:nth-child(6) {
  --throb-delay: 2500ms;
}
.orbit li:nth-child(7) {
  --throb-delay: 3000ms;
}
.orbit li:nth-child(8) {
  --throb-delay: 3500ms;
}
.orbit li > * {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: inherit;
  position: absolute;
  animation: orbit var(--speed) linear reverse infinite, throb 1s var(--throb-delay) ease-in-out infinite alternate;
  animation-play-state: var(--orbit-play-state, running), var(--throb-play-state, running);
}
.orbit li > div {
  background-color: var(--icon-bg);
}
.orbit li > p {
  background: var(--text-bg);
  transition: opacity 500ms;
  opacity: 0;
}
.orbit li:hover > p {
  opacity: 1;
}
.orbit li:nth-child(1) {
  --icon-bg: var(--blue-light);
  --text-bg: var(--blue-dark);
  transform: translate(15rem, 0rem);
}
.orbit li:nth-child(2) {
  --icon-bg: var(--green-light);
  --text-bg: var(--green-dark);
  transform: translate(10rem, 10rem);
}
.orbit li:nth-child(3) {
  --icon-bg: var(--pink-light);
  --text-bg: var(--pink-dark);
  transform: translate(0, 15rem);
}
.orbit li:nth-child(4) {
  --icon-bg: var(--red-light);
  --text-bg: var(--red-dark);
  transform: translate(-10rem, 10rem);
}
.orbit li:nth-child(5) {
  --icon-bg: var(--brown-light);
  --text-bg: var(--brown-dark);
  transform: translate(-15rem, 0rem);
}
.orbit li:nth-child(6) {
  --icon-bg: var(--yellow-light);
  --text-bg: var(--yellow-dark);
  transform: translate(-10rem, -10rem);
}
.orbit li:nth-child(7) {
  --icon-bg: var(--purple-light);
  --text-bg: var(--purple-dark);
  transform: translate(0, -15rem);
}
.orbit li:nth-child(8) {
  --icon-bg: var(--orange-light);
  --text-bg: var(--orange-dark);
  transform: translate(10rem, -10rem);
}

@keyframes orbit {
  100% {
    rotate: 1turn;
  }
}
@keyframes hold-position {
  100% {
    rotate: -1turn;
  }
}
@keyframes throb {
  100% {
    scale: 1.05;
  }
}

/*# sourceMappingURL=style.css.map */

.sliderRound{
    background-color: rgb(242, 242, 242);
    backdrop-filter: blur(10px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    margin: 31px 0 41px 4px;
    height: 70vh;
    width: 100%;
}


@media screen and (max-width: 510px) {

  .sliderRound{
   display: none !important;
}
}