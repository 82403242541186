.container {
    position: relative;
    width: 100%;
    /* height: auto; */
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.imagecontainer {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10;
    position: relative;
}



#carousel {

    border: 1px solid #ccc;
    position: absolute;
    display: flex;
    justify-content: space-between;
    /* overflow: hidden; */
    justify-content: center;
    bottom: 500px;
    /* padding-bottom: 100px; */

}

.controls {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    text-align: center;
    /* padding-top: 150px; */
    /* padding-bottom: 100px; */
    justify-content: center;
    position: absolute;
    
    margin-top: 340px;
    
}


.icons-container {
    display: flex;
    align-items: center;
}

.carousel-icon {
    font-size: 2rem;
    cursor: pointer;
    color: #333;
}

.pics {
    box-sizing: border-box;
    background-color: #fcfbfb;
    display: block;
    position: absolute;
    /* width: 200px; */
    /* height: 170px; */
    top: 32.5px;
    transition: all 0.6s ease-out;
    box-shadow: 0 0 10px rgba(55, 69, 190, 0.5);
    /* Add box shadow with black color */
}

.pic1 {
    background: lightgray 50% / cover no-repeat;
    border-radius: 10px;

}

.pics.pic1 {
    transform: translate(0, 40.57px);
    z-index: 15;
}

.pics.pic2 {
    transform: translate(180.82px, 11px) scale(0.8);
    z-index: 14;
}

.pics.pic3 {
    transform: translate(340px, -40.5px) scale(0.8);
    z-index: 13;

}

.pics.pic4 {
    transform: translate(430px, -77.5px) scale(0.7);
    z-index: 12;

}

.pics.pic5 {
    transform: translate(505.82px, -100px) scale(0.7);
    z-index: 11;

}

.pics.pic6 {
    transform: translate(355.73px, -145.57px) scale(0.7);
    z-index: 10;
}

.pics.pic7 {
    transform: translate(180.73px, -145.57px);
    z-index: 9;
}

.pics.pic8 {
    transform: translate(107.64px, -185px);
    z-index: 8;
    /* display: none; */

}

.pics.pic9 {
    transform: translate(-107.45px, -197.5px) scale(0.9);
    z-index: 8;
    /* display: none; */

}

.pics.pic10 {
    transform: translate(-180.27px, -145.57px);
    z-index: 9;
}


.pics.pic11 {
    transform: translate(-355.73px, -145.57px) scale(0.7);
    z-index: 10;
}

.pics.pic12 {
    transform: translate(-505.82px, -100px) scale(0.7);
    z-index: 12;
}

.pics.pic13 {

    transform: translate(-430.73px, -77.5px) scale(0.7);
    z-index: 12;

}

.pics.pic14 {
    transform: translate(-340.64px, -40.5px) scale(0.8);
    z-index: 13;
}

.pics.pic15 {
    transform: translate(-201.55px, 10.5px);
    z-index: 14;

}




.carousel-icon {
    width: 50px;
    height: 50px;
    border: 1px solid black;
    border-radius: 50%;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .container {
        position: relative;
        width: 100%;
        /* height: auto; */
        display: flex;
        justify-content: center;
        align-items: center;

        /* margin-left: 217px; */
    }

    #carousel {

        position: absolute;
        border: 1px solid #ccc;
        /* position: relative; */
        display: flex;
        justify-content: space-between;
        /* overflow: hidden; */
        justify-content: center;
        align-items: center;
        
        bottom: 300px;
        /* padding-bottom: 100px; */

    }

    .icons-container {
        display: flex;
        align-items: center;
    }

    .carousel-icon {
        font-size: 2rem;
        cursor: pointer;
        color: #333;
    }

    .pics {

        box-sizing: border-box;
        background-color: #fff;
        display: block;
        position: absolute;
        /* width: 200px; */
        /* height: 170px; */
        top: 32.5px;
        transition: all 0.6s ease-out;
    }

    .pic1 {
        background: lightgray 50% / cover no-repeat;

    }

    .pics.pic1 {
        transform: translate(4px, 24.57px) scale(0.4);
        z-index: 15
    }

    .pics.pic2 {
        transform: translate(43.82px, -7px) scale(0.4);
        z-index: 14;
    }

    .pics.pic3 {
        transform: translate(89px, -40.5px) scale(0.3);
        z-index: 13;

    }

    .pics.pic4 {
        transform: translate(120.82px, -78px) scale(0.3);
        z-index: 12
    }

    .pics.pic5 {
        transform: translate(141.82px, -100px) scale(0.3);
        z-index: 11;


    }

    .pics.pic6 {
        transform: translate(89.73px, -118.57px) scale(0.3);
        z-index: 10;
    }

    .pics.pic7 {
        transform: translate(62.73px, -111.57px)scale(0.4);
        z-index: 9
    }

    .pics.pic8 {
        transform: translate(55.64px, -137px) scale(0.4);
        z-index: 8;
        /* display: none; */

    }

    .pics.pic9 {
        transform: translate(-29.55px, -137.5px) scale(0.4);
        z-index: 8;
        /* display: none; */

    }

    .pics.pic10 {
        transform: translate(-45.73px, -112.57px) scale(0.4);
        z-index: 9
    }


    .pics.pic11 {
        transform: translate(-86.73px, -122.57px) scale(0.3);
        z-index: 10
    }

    .pics.pic12 {
        transform: translate(-141.18px, -100px) scale(0.3);
        z-index: 12;
    }

    .pics.pic13 {

        transform: translate(-120.27px, -77.5px) scale(0.3);
        z-index: 12
    }

    .pics.pic14 {
        transform: translate(-88.64px, -40.5px) scale(0.3);
        z-index: 13;
    }

    .pics.pic15 {
        transform: translate(-39.45px, -7.5px) scale(0.4);
        z-index: 14;

    }


    .controls {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 10px;
        display: none;
    }
    .controls {
        display: none;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        text-align: center;
        /* padding-top: 150px; */
        /* padding-bottom: 100px; */
        justify-content: center;
        position: absolute;
        
        margin-top: 490px;
        
    }
    .imagecontainer {
        /* height: 250px; */
        width: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10;
        position: relative;
    }
    

    .carousel-icon {
        width: 50px;
        height: 50px;
        border: 1px solid black;
        border-radius: 50%;
        align-items: center;
    }

}




@media screen and (max-width: 625px) {
    .container {
        position: relative;
        width: 100%;
        /* height: auto; */
        display: flex;
        justify-content: center;
        align-items: center;

        /* margin-left: 217px; */
    }

    #carousel {

        position: absolute;
        border: 1px solid #ccc;
        /* position: relative; */
        display: flex;
        justify-content: space-between;
        /* overflow: hidden; */
        justify-content: center;
        align-items: center;
        
        bottom: 300px;
        /* padding-bottom: 100px; */

    }

    .icons-container {
        display: flex;
        align-items: center;
    }

    .carousel-icon {
        font-size: 2rem;
        cursor: pointer;
        color: #333;
    }

    .pics {

        box-sizing: border-box;
        background-color: #fff;
        display: block;
        position: absolute;
        /* width: 200px; */
        /* height: 170px; */
        top: 32.5px;
        transition: all 0.6s ease-out;
    }

    .pic1 {
        background: lightgray 50% / cover no-repeat;

    }

    .pics.pic1 {
        transform: translate(4px, -18.43px) scale(0.3);
        z-index: 15
    }

    .pics.pic2 {
        transform: translate(41.82px, -37px) scale(0.3);
        z-index: 14;
    }

    .pics.pic3 {
        transform: translate(72px, -47.5px) scale(0.3);
        z-index: 13;

    }

    .pics.pic4 {
        transform: translate(99.82px, -64px) scale(0.3);
        z-index: 12
    }

    .pics.pic5 {
        transform: translate(112.82px, -87px) scale(0.3);
        z-index: 11;


    }

    .pics.pic6 {
        transform: translate(79.73px, -103.57px) scale(0.3);
        z-index: 10;
    }

    .pics.pic7 {
        transform: translate(44.73px, -109.57px) scale(0.3);
        z-index: 9
    }

    .pics.pic8 {
        transform: translate(43.64px, -137px) scale(0.3);
        z-index: 8;
        /* display: none; */

    }

    .pics.pic9 {
        transform: translate(-31.55px, -137.5px) scale(0.3);
        z-index: 8;
        /* display: none; */

    }

    .pics.pic10 {
        transform: translate(-30.73px, -111.57px) scale(0.3);
        z-index: 9
    }


    .pics.pic11 {
        transform: translate(-62.73px, -107.57px) scale(0.3);
        z-index: 10
    }
    .pics.pic12 {
        transform: translate(-107.18px, -89px) scale(0.3);
        z-index: 12;
    }

    .pics.pic13 {
        transform: translate(-91.27px, -65.5px) scale(0.3);
        z-index: 12
    }
 
        .pics.pic14 {
            transform: translate(-55.64px, -47.5px) scale(0.3);
        z-index: 13;
    }

    .pics.pic15 {
        transform: translate(-33.45px, -37.5px) scale(0.3);
        z-index: 14;

    }


    .controls {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 10px;
        display: none;
    }
    .controls {
        display: none;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        text-align: center;
        /* padding-top: 150px; */
        /* padding-bottom: 100px; */
        justify-content: center;
        position: absolute;
        
        margin-top: 490px;
        
    }
    .imagecontainer {
        /* height: 250px; */
        width: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10;
        position: relative;
    }
    

    .carousel-icon {
        width: 50px;
        height: 50px;
        border: 1px solid black;
        border-radius: 50%;
        align-items: center;
    }

}